import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import ApiService from "@/utils/ApiServices";
import ApiUrl from "@/utils/ApiUrl";
import {loginAction} from "@/redux/action/AuthAction";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useRouter} from "next/router";
import {useState} from "react";


export default function GoogleLoginButton() {
  const dispatch = useDispatch()
  const router = useRouter()
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (userInfo) => {
    // e.preventDefault();
    dispatch({ type: "AUTH_REQUEST" }); // Dispatch loading state
    const userData ={
      email: userInfo.email,
      firstName: userInfo.name,
      lastName: userInfo.name,
      userType: 'google',
      roleId: 3
    }

    try {
      const response =  await ApiService.postApiService(ApiUrl.LOGIN_URL, userData);
      if (response.status === 'success') {

      const { user, token } = response.data; // Adjust based on your API response
      dispatch(loginAction(user, token)); // Dispatch login action
        toast.success(response.message || 'Registration successful! Redirecting to course page...', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Redirect after a delay to show the toast notification
        router.push('/');
      }
    } catch (error) {
      const errorMsg = error.response?.data?.message || "Login failed";
      setErrorMessage(errorMsg); // Set error message to state
      dispatch({
        type: "AUTH_FAILURE",
        payload: errorMsg,
      });
    }
  };

  const handleSuccess = (response) => {
    console.log(response); // Check the entire response
    if (response.credential) {
      const userInfo = jwtDecode(response.credential);
      console.log(userInfo); // Process user information
      handleSubmit(userInfo)
    } else {
      console.error("No credential in the response");
    }
  };

  const handleError = () => {
    console.error('Google login failed');
  };

  return (
    // <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
    //   <GoogleLogin
    //     onSuccess={handleSuccess}
    //     onError={handleError}
    //   />
    // </GoogleOAuthProvider>

     <div className="d-flex justify-content-center">
    {/*     <GoogleOAuthProvider className = " bg-info" clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>*/}
    {/*  <GoogleLogin*/}
    {/*    onSuccess={handleSuccess}*/}
    {/*    onError={handleError}*/}
    {/*    theme="outline" // Optional: Customize the button theme (filled, outline)*/}
    {/*    text="signin_with" // Optional: Customize the button text*/}

    {/*  />*/}
    {/*</GoogleOAuthProvider>*/}
       <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
  <GoogleLogin
    onSuccess={handleSuccess}
    onError={handleError}
    theme="outline" // Optional: Customize the button theme (filled, outline)
    text="signin_with" // Optional: Customize the button text
    // Customize the button appearance using the render prop
    render={(renderProps) => (
      <button
        onClick={renderProps.onClick}
        disabled={renderProps.disabled}
        className="btn btn-info btn-lg d-flex align-items-center justify-content-center w-100"
      >
        <i className="bi bi-google me-2"></i> {/* Bootstrap Google Icon */}
        Sign in with Google
      </button>
    )}
  />
</GoogleOAuthProvider>

     </div>
  );
}
