// import React,{useEffect,useState} from "react";
// import { useRouter } from "next/router";
//
// import BackToTop from "@/pages/backToTop";
// import Separator from "../Common/Separator";
// import FooterOne from "../Footer/Footer-One";
// import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
// import NewsletterThree from "../Newsletters/Newsletter-Three";
// import Context from "@/context/Context";
// import Store from "@/redux/store";
// import { Provider } from "react-redux";
//
//
//
//
// import MobileMenu from "../Header/MobileMenu";
// import Cart from "../Header/Offcanvas/Cart";
// const Layout = ({ children }) => {
//     const router = useRouter();
//
//     // Set the initial state based on the current route
//     const [isUrl, setUrl] = useState(() =>
//         router.pathname === "/login" || router.pathname === "/signup"
//     );
//
//     useEffect(() => {
//         const handleRouteChange = (url) => {
//             if (url === "/login" || url === "/signup") {
//                 setUrl(true);
//                 console.log("Condition is false");
//             } else {
//                 setUrl(false);
//                 console.log("Condition is true");
//             }
//         };
//
//         // Listen for route changes
//         router.events.on("routeChangeComplete", handleRouteChange);
//
//         // Cleanup the event listener when the component unmounts
//         return () => {
//             router.events.off("routeChangeComplete", handleRouteChange);
//         };
//     }, [router.pathname]);
//
//
//     return (
//         <Provider store={Store}>
//         <Context>
//             <HeaderStyleTen isUrl={isUrl} headerSticky="rbt-sticky" headerType={true} />
//             <MobileMenu />
//             <Cart />
//             <main>{children}</main>
//
//             {/* <div className="rbt-newsletter-area bg-gradient-6 ptb--50">
//             <NewsletterThree />
//
//         </div> */}
//             <BackToTop />
//             <Separator />
//             <FooterOne />
//         </Context>
//         </Provider>
//     );
// };
//
// export default Layout;

import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import BackToTop from "@/pages/backToTop";
import Separator from "../Common/Separator";
import FooterOne from "../Footer/Footer-One";
import HeaderStyleTen from "@/components/Header/HeaderStyle-Ten";
import NewsletterThree from "../Newsletters/Newsletter-Three";
import Context from "@/context/Context";
import Store, { persistor } from "@/redux/store"; // Import persistor
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate

import MobileMenu from "../Header/MobileMenu";
import Cart from "../Header/Offcanvas/Cart";

const Layout = ({ children }) => {
  const router = useRouter();

  // Set the initial state based on the current route
  const [isUrl, setUrl] = useState(() =>
    router.pathname === "/login" || router.pathname === "/signup"
  );

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (url === "/login" || url === "/signup") {
        setUrl(true);
        console.log("Condition is false");
      } else {
        setUrl(false);
        console.log("Condition is true");
      }
    };

    // Listen for route changes
    router.events.on("routeChangeComplete", handleRouteChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.pathname]);

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <Context>
          <HeaderStyleTen isUrl={isUrl} headerSticky="rbt-sticky" headerType={true} />
          <MobileMenu />
          <Cart />
          <main>{children}</main>
          <BackToTop />
          <Separator />
          <FooterOne />
        </Context>
      </PersistGate>
    </Provider>
  );
};

export default Layout;

