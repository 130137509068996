import axios from 'axios';
import { toast } from 'react-toastify';
import {useRouter} from "next/router";
import {logoutAction} from "@/redux/action/AuthAction";
import {useDispatch} from "react-redux"; // Import useDispatch
const ApiService = {


  // async getApiService(url,token,userId) {
  //   console.log(token,userId,'jky')
  //   try {
  //    const headers = {
  //      'Authorization': `Bearer ${token}`,
  //   };
  //     const response = await axios.get(url,{ user_id: userId }, {headers});
  //     return response.data;
  //   } catch (error) {
  //     handleError(error);
  //   }
  // },

  async getApiService(url, token, userId) {
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };
        // Use query parameters to include userId in the request
        const response = await axios.get(url, {
            headers,
            params: { user_id: userId },  // Pass userId here as a query parameter
        });
        return response.data;
    } catch (error) {
        handleError(error);
    }
},


  async getApiDetails(url, config = {}) {
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },


  async postApiService(url, data, token, config = {}) {
  try {
     const headers = {
       'Authorization': `Bearer ${token}`,
    };
    const response = await axios.post(url, data, {headers});
    return response.data;
  } catch (error) {
    handleError(error);
  }
},


  async putApiService(url, data, token, config = {}) {
    try {
      console.log(data, url, config)
      const sharedSecret = process.env.NEXT_PUBLIC_GATEWAY_SHARED_SECRET;
      const headers = {
        'x-shared-secret': sharedSecret,
        'Authorization': `Bearer ${token}`,
      };
      const response = await axios.put(url, data, {headers});
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },


  async deleteApiService(url, config = {}) {
    try {
      const response = await axios.delete(url, config);
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
};


const handleError = (error) => {
  const router = useRouter()
  const dispatch = useDispatch()
  if (!error.response) {
    console.error('Network error:', error);
    throw new Error('Network error. Please try again later.');
  }
  const { status, data } = error.response;
  switch (status) {
    case 404:
      console.error('Not Found:', data.message || 'The requested resource was not found.');
      toast.error(data.message);
      throw new Error('Resource not found.');
    case 403:
      console.error('Forbidden:', data.message || 'You do not have permission to access this resource.');
      toast.error(data.message);
      // throw new Error('Access forbidden.');
      dispatch(logoutAction());
      router.push('/login')
    case 500:
      console.error('Server Error:', data.message || 'An unexpected error occurred on the server.');
      toast.error(data.message);
      throw new Error('Server error. Please try again later.');
    default:
      console.error('Error:', data.message || 'An unexpected error occurred.');
      toast.error(data.message);
      throw new Error(data.message || 'An unexpected error occurred.');
  }
};

export default ApiService;