const getAuthFromLocalStorage = () => {
  if (typeof window !== "undefined") {
    let auth = localStorage.getItem("authData");
    if (auth) {
      return JSON.parse(auth);
    }
  }
  return { userInfo: null, token: null };
};

const authInit = {
  userInfo: getAuthFromLocalStorage().userInfo,
  token: getAuthFromLocalStorage().token,
  isLoggedIn: !!getAuthFromLocalStorage().token, // Check if token exists
  loading: false,
  error: false,
  msg: "",
};

export const AuthReducer = (state = authInit, action) => {
  switch (action.type) {
    case "AUTH_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        msg: "",
      };
    case "AUTH_SUCCESS":
      const { userInfo, token } = action.payload;

      // Save auth data to localStorage
      if (typeof window !== "undefined") {
        localStorage.setItem(
          "authData",
          JSON.stringify({ userInfo, token })
        );
      }

      return {
        ...state,
        userInfo,
        token,
        isLoggedIn: true, // Set isLoggedIn to true on successful login
        loading: false,
        error: false,
        msg: "Login successful",
      };
    case "AUTH_FAILURE":
      return {
        ...state,
        loading: false,
        error: true,
        msg: action.payload || "Login failed",
      };
    case "LOGOUT":
      if (typeof window !== "undefined") {
        localStorage.removeItem("authData");
      }
      return {
        ...state,
        userInfo: null,
        token: null,
        isLoggedIn: false, // Set isLoggedIn to false on logout
        loading: false,
        error: false,
        msg: "Logged out",
      };
    case "REFRESH_TOKEN":
      if (typeof window !== "undefined") {
        const newAuthData = {
          userInfo: state.userInfo,
          token: action.payload,
        };
        localStorage.setItem("authData", JSON.stringify(newAuthData));
      }

      return {
        ...state,
        token: action.payload,
        isLoggedIn: true, // Ensure isLoggedIn stays true after token refresh
        msg: "Token refreshed",
      };
    case "AUTH_ERROR":
      return {
        ...state,
        error: true,
        msg: action.payload || "Authentication error",
      };
    case "CLEAR_AUTH_ERROR":
      return {
        ...state,
        error: false,
        msg: "",
      };
    default:
      return state;
  }
};
