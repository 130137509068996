// import { createStore, combineReducers, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
//
// // import ProductsReducer from "./reducer/ProductsReducer";
// import { CartReducer } from "./reducer/CartReducer";
// import { AuthReducer } from "./reducer/AuthReducer"
// const root = combineReducers({
//   //   ProductsReducer,
//   CartReducer,
//   AuthReducer
// });
//
// const middleware = [thunk];
//
// const Store = createStore(
//   root,
//   composeWithDevTools(applyMiddleware(...middleware))
// );
// export default Store;

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import { CartReducer } from "./reducer/CartReducer";
import { AuthReducer } from "./reducer/AuthReducer";

// Configuring persist for AuthReducer (for example)
const persistConfig = {
  key: "root", // key for localStorage
  storage,
  whitelist: ["AuthReducer"], // Reducers to persist
};

const rootReducer = combineReducers({
  CartReducer,
  AuthReducer,
});

// Wrap your reducers with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

// Create store with persisted reducer
const Store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor = persistStore(Store); // export persistor
export default Store;

