import React, { useEffect } from "react";

import "bootstrap/scss/bootstrap.scss";
import "../public/scss/default/euclid-circulara.scss";

// ========= Plugins CSS START =========
import "../node_modules/sal.js/dist/sal.css";
import "../public/css/plugins/fontawesome.min.css";
import "../public/css/plugins/feather.css";
import "../public/css/plugins/odometer.css";
import "../public/css/plugins/animation.css";
import "../public/css/plugins/euclid-circulara.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import 'react-toastify/dist/ReactToastify.css';
// ========= Plugins CSS END =========

import "../public/scss/styles.scss";
import "../public/css/custom.css"

import Layout from "@/components/Layouts";
import {ToastContainer} from "react-toastify";
import {useRouter} from "next/router";

export default function App({ Component, pageProps }) {
  // useEffect(() => {
  //   require("bootstrap/dist/js/bootstrap.bundle.min.js");
  //
  //
  // }, []);
    const router = useRouter();

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");

    // Google Analytics setup
    const handleRouteChange = (url) => {
      window.gtag("config", process.env.NEXT_PUBLIC_GA_ID, {
        page_path: url,
      });
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return  (
    <Layout>
      <>
      <ToastContainer position="top-right"  />
      <Component {...pageProps} />
        </>
    </Layout>
  );
}
