import Link from "next/link";
import Separator from "../Common/Separator";
import {useSelector} from "react-redux";

const CopyRight = () => {
  const userInfo = useSelector((state) => state.AuthReducer.userInfo);
  return (
    <>
      <Separator />
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-start">
                Copyright © 2024{" "}
                <Link href="https://breatheinwisdom.com/">
                Breathin Wisdom

                </Link>{" "}
                All Rights Reserved
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <Link href="/pages/terms-of-service">Terms and conditions</Link>
                </li>
                <li>
                  <Link href="/pages/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link href="/offers">Offers</Link>
                </li>

                  {userInfo ? (
                      ''
                    // <Link href="/profile">Profile</Link>
                  ) : (
                      <li>
                    <Link href="/signup">Login & Register</Link>
                          </li>
                  )}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
